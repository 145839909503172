import React from "react";
import GeneralSection from "../newComponents/generalSection";

const SectionAreas = ({children}) => (
  <GeneralSection className="section-areas">
    <p className="section__title title h2 text-gray">Areas of Expertise</p>
    <div className="list-areas">
      <ul>{children}</ul>
    </div>
  </GeneralSection>
);

export default SectionAreas;